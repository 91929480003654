<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        <b>Part a:</b>
        When
        <chemical-latex content="NH3" />
        is added to a metal cation and a solid forms what is the anion attached to the metal?
      </p>
      <v-text-field v-model="inputs.input1" class="mb-3" outlined />

      <p>
        <b>Part b:</b>
        Write the balanced chemical equation for the formation of a solid when
        <chemical-latex content="NH3" />
        is added to
        <chemical-latex content="CuSO4." />
      </p>
      <chemical-notation-input
        v-model="inputs.input2"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />

      <p>
        <b>Part c:</b>
        Write the balanced net ionic chemical equation for the reaction above in part b) with
        <chemical-latex content="HCl(aq)" />
      </p>
      <chemical-notation-input
        v-model="inputs.input3"
        dense
        :show-note="false"
        class="mb-4"
        :disabled="!allowEditing"
      />

      <p>
        <b>Part d:</b>
        Basing an argument on Le Chatelier's Principle, explain the changes in part b).
      </p>
      <v-text-field v-model="inputs.input4" class="mb-3" outlined />

      <p>
        <b>Part e:</b>
        Basing an argument on Le Chatelier's Principle, explain the changes in part c).
      </p>
      <v-text-field v-model="inputs.input5" class="mb-3" outlined />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220Oct10Q1',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
    };
  },
};
</script>
